<template>
  <div :style="getElementStyle">
    <span style="display: none">{{ isDefalutPos }}</span>
    <el-row>
      <!-- <el-col :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24">
        <label for="text">{{ data.label }} </label>
        <span v-if="data.validations.required" style="color: red; font-weight: bold">
          *
        </span>
      </el-col> -->

      <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <span v-if="data.description">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
      </el-col>
      <el-row v-if="isActive" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
      </el-row>
      <el-col :span="showLabel && isDefalutPos ? 24 : 19">
        <el-button type="success" round>{{ data.label }}  {{data.validations.payAmount}} {{ data.validations.currency }}</el-button>
        <!-- <el-input :style="getStyle" :placeholder="data.placeholder" v-model="selectedValue"
          :suffix-icon="data.properties.el_icon"></el-input> -->

      </el-col>
    </el-row>

    <!--<div>
    <div>
      
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
      <span v-if="isActive" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
    </div>
    <el-input :style="getStyle" :placeholder="data.placeholder" v-model="selectedValue"></el-input>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SingleLineTextView",
  components: {},
  props: ["data", "value", "isActive"],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo", "getIntegratedList"]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }

      inputStyle += ";";
      inputStyle = inputStyle + `width: ${this.data.width}px !important;`;
      return inputStyle;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    },
    getElementStyle() {
      let borderStyle = "";

      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
            this.data.styles.font &&
            this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }

      return borderStyle;
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      isDefalutPosq: true,
      showLabel: true,
      // validations: [],
      // isDefalutPos: true,
      // showLabel: true
    };
  },
  mounted() {
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        if (this.data.styles.labelStyle == "right") {
          this.isDefalutPos = false;
        }
      }
    }

    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    // if(this.data)
    // {
    // this.getActivePaymentSystemsData();
    // }
  },
  methods: {
    async getActivePaymentSystemsData() {
      // this.loading = true;
      await this.$store.dispatch("paymentGateway/fetchInitSystemsData");
      // this.loading = false;
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
      
        if (this.getGlobalVariableById.input_type == "SINGLE_LINE_TEXT") {
        
          // this.isList = true;
          this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {
      
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input__inner {
  height: inherit !important;
}
</style>